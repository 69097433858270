<template>
  <v-dialog v-model="dialog" width="900" :fullscreen="$vuetify.breakpoint.xs">
    <template v-slot:activator="{ on }">
      <base-border-card
        :title="$tc('reminder', 2)"
        @card_clicked="dialog = true"
      >
      </base-border-card>
    </template>
    <v-card>
      <v-card-title>
        {{ $tc("reminder", 2) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="start">
          <v-col cols="12" align="right">
            <NewAlert :appointment="appointment" @update="fetchAlertsById" />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="alerts"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon small class="mx-1" @click="del(item)">
              <v-icon size="16">$delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "AlertList",
  props: ["appointment"],
  data() {
    return {
      alerts: [],
      dialog: false,
      alert: {
        appointment_id: null,
        type: null,
        sub_type: null,
        startDate: null,
        startTime: null,
        endTime: null,
        all_day: false,
        title: "",
        description: null,
      },
      headers: [
        {
          text: this.$t("alerts.title"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("alerts.type"),
          value: "type",
          sortable: false,
        },
        {
          text: this.$t("alerts.sub_type"),
          value: "sub_type",
          sortable: false,
        },
        {
          text: this.$t("alerts.date"),
          value: "start",
          sortable: false,
        },
        {
          text: this.$t("alerts.description"),
          value: "description",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.fetchAlertsById();
  },
  components: {
    NewAlert: () => import("@/components/alert/Alerts.vue"),
  },
  methods: {
    ...mapActions("alerts", ["getAlertsById", "deleteAlert", "editAlert"]),
    fetchAlertsById() {
      console.log("FETCH ALERTS");
      this.getAlertsById(this.appointment.id).then((response) => {
        console.log(response);
        this.alerts = response;
      });
    },

    del(alert) {
      this.deleteAlert(alert.id).then((response) => {
        console.log(response);
        this.$alert(
          this.$t("delete_alert_confirm", {
            type: this.title,
          })
        );
        this.fetchAlertsById();
      });
    },
  },
};
</script>
